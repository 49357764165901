:root{
    --background-color: #fff;
    --background-light: #fff;
    --shadow-color: rgba(0,0,0,0.2);
    --primary-color: rgb(255, 0, 86);
    --text-color: #0A0A0A;
    --text-light: #575757;
    --font-size: 16px;
    --animation-speed: 1;
}
@function getAnimationDuration($duration: number) {
    @return calc($duration * var(--animation-speed));
}

body{
    font-family: var(--font-size);
    font-family: 'Roboto', sans-serif;
    padding-top: 55px;
}
a{
    text-decoration: none;
}
img{
    object-fit: cover;
}
.btn{
    border: none;
    outline: none;
    background-color: var(--primary-color);
    color: #fff;
    padding: .75em 1em;
    text-transform: uppercase;
    font-size: 1.1em;
    font-weight: bold;
    letter-spacing: 1px;
    border-radius: 3px;
    box-shadow: 0 3px 6px var(--shadow-color);
    &:hover{
        box-shadow: 0 1px 3px var(--shadow-color);
    }



}
.custom-image{
    background-color: red;
    height: 0;
    width: 100%;
    position: relative;
    img{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

}

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
@import "./partials/navbar";

.section{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 3vw;
    &.d-block{
        display: block;
    }
    .title{
        color: var(--text-color);
        font-size: 2em;
        margin-bottom: .5em;
    }
    .info{
        color: var(--text-light);
        font-size: 1em;
        letter-spacing: 1px;
        line-height: 1.3;
        margin-bottom: 1.25em;

    }
    .col{
       width: 50%;

    }
}

@import './partials//hero';

@import './partials/improveSkill';

@import './partials/quots';

@import './partials//chief';

.footer{
    background-color: #0f0f0f;
    color: #fff;
    padding-block: 3em;
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    grid-gap:1em ;
    .footer-section{
        p{
            opacity: .9;
            margin-bottom: .5em;
        }
        p.title{
            opacity: 1;
            font-size: 1.1em;
            font-weight: bold;
            margin-bottom: 1em;
        }
    }
}

.previous-searchs{
    display: block;
    h2{
        color: var(--text-color);
        margin-bottom: 1em;
    }
    .previous-searchs-content{
        .search-item{
            background-color: var(--primary-color);
            display: inline-block;
            padding: .5em 1em;
            margin: 0 .5em .5em 0;
            border-radius: 30px;
            color: #fff;
            box-shadow: 0 2px 6px var(--shadow-color);
            animation:  getAnimationDuration(.5s) slideInRight;

        }
    }

    .search-box{
        display: flex;
        height: 35px;
        justify-content: flex-end;
        input,.btn{
            height: 30px;
        }
        input{
            min-width: 250px;
            padding: 0 0.5em;
            border-radius: 5px 0 0 5px;
            outline: none;
            border: 1px solid#4d4d4d;

        }
        .btn{
            padding-block: 0;
            padding-inline: .75em;
            border-radius: 0 5px 5px 0;
        }
    }
}


.recipes-container{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    grid-gap: 1.5em;
}
.recipe-card{
    isolation: isolate;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 5px 15px var(--shadow-color);
    background-color: var(--background-color);
    .recipe-card-info{
        padding: 15px;
    }
    .custom-image{
        z-index: -1;
    }
    .auther-img{
        height: 60px;
        width: 60px;
        border-radius: 50%;
        border: 3px solid var(--background-light);
        box-shadow: 0 1px 3px var(--shadow-color);
        margin-top: -50px;
        z-index: 2;
    }
    .recipe-title{
        font-size: 1.25em;
        font-weight: bold;
        margin-bottom: .5em;
        color: var(--text-color);
        margin-top: .25em;
    }
    .recipe-desc{
        color: var(--text-light);
        font-size: .9em;
        letter-spacing: 1px
    }
    .view-btn{
        font-size: 1em;
        font-weight: bold;
        color: var(--primary-color);
        margin-top: .75em;
        display: inline-block;
    }
}


.main{
    padding-block: 3vw;
}

.container{
    padding-inline: 25vw;
}


.options-container{
    box-shadow: 0 5px 10px var(--shadow-color);
    padding: 1em;
    border-radius: 5px;
    border-left: .5em solid var(--primary-color);
    background-color: var(--background-light);
    margin-top: 1em;
    display: flex;
    gap: 1em;
    .option{
        height: 3.5em;
        width: 3.5em;
        box-shadow: 0 3px 6px var(--shadow-color);
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        &.light{
            background-color: #fff;
        }
        &.dark{
            background-color: rgb(29, 29, 29);
        }
        .check{
            height: 2.5em;
            width: 2.5em;
            background-color: var(--primary-color);
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: 0 2px 5px var(--shadow-color);
            svg{
                height: 1.5em;
                width: 1.5em;
            }
        }
    }
    .btn span{
        display: inline-block;
        margin-left: .5em;
    }
}
.d-block h2{
    font-size: 1.75em;
    color: var(--text-color)
}

@import './partials/animation';
@media screen and (max-width: 1280px) {
    .container{
        padding-inline: 18vw;
    }
}
@media screen and (max-width: 1050px) {
    .container{
        padding-inline: 12vw;
    }
}


@media screen and (max-width: 786px) {
    .navbar{
        .nav-links{
            display: none;
        }
        .sidebar-btn{
            display: block;
        }
    }
    .section{
        .col{
            width: 100%;
            padding: 0 !important;
            text-align: center;
            margin-bottom: 2em;
        }
    }
    .chief-card{
        box-shadow: 0 5px 10px var(--shadow-color);
    }
    .container{
        padding-inline: 6vw;
    }
    .footer{
        grid-template-columns: repeat(4, 1fr);
        .footer-section:nth-child(1){
            grid-column: span 4;
        }
    }

    
}
@media screen and (max-width: 480px) {
    .container{
        padding-inline: 3vw;
    }
}