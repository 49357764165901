.section.hero{
    .typography{
        padding-right: 1em;
    }
    .gallery{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 2vw;
        .custom-image{
            transform: scale(0);
            opacity: 0;
            animation: getAnimationDuration(.5s) popOut forwards;
            &:nth-child(2){
                animation-delay: .1s;
            }
            &:nth-child(3){
                animation-delay: .2s;
            }
            &:nth-child(6){
                animation-delay: .3s;
            }
            &:nth-child(9){
                animation-delay: .4s;
            }
            &:nth-child(8){
                animation-delay: .5s;
            }
            &:nth-child(7){
                animation-delay: .6s;
            }
            &:nth-child(4){
                animation-delay: .7s;
            }
            &:nth-child(5){
                animation-delay: .8s;
            }
            &:nth-child(4){
                animation-delay: .7s;
            }
        }
    }
}
